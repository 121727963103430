const internalLinks = ['#open-popup', '#close-popup', '#submit-form', '#scroll', '#print', '#show-hide']
function setupActionLinkListeners() {
  // Print Page (webinars)
  $(document).on('click', "a[href='#print'], [data-element-link='#print']", function () {
    window.print()

    return false
  })

  $(document).on('click', "[data-element-link='#open-popup'], [href='#open-popup']", function (evt) {
    const checkIfShowHide = evt.target.parentElement.getAttribute('data-elbuttontype')
    if (typeof checkIfShowHide == 'undefined' || !['2', 'showHide'].includes(checkIfShowHide)) {
      globalThis.CFOpenPopup()
    }
  })

  $(document).on('click', "[data-element-link='#close-popup'], [href='#close-popup']", function () {
    globalThis.CFClosePopup()
  })

  // Scroll To Block Area
  $(document).on('click', "a[href*='#scroll-'], [data-element-link*='#scroll-']", function (evt) {
    const href = evt.currentTarget.getAttribute('href') ?? ''
    const [, id] = href.split('#scroll-')

    if (id) {
      const $el = $(`.${id}`)
      const popupParent = $el.parents('.modal-wrapper')
      $(popupParent.length > 0 ? popupParent : 'html, body').animate({ scrollTop: $el.offset().top }, 500)
    }

    return false
  })

  const getElementsIds = (evt, type) =>
    (evt.currentTarget.getAttribute(`data-${type}-button-ids`) || '').split(',').filter((s) => s)

  const performActionOnId = (id: string, action: 'fadeOut' | 'fadeIn') => {
    const $el = $(`.${id}`) as any
    if ($el.attr('data-show-only') && action === 'fadeIn') {
      $el.removeAttr('data-show-only')
      $el.css('display', 'none')
    }
    $el[action]({ duration: 500 })
  }

  // Show / Hide
  $(document).on('click', "a[href*='#show-hide'], [data-element-link*='#show-hide']", function (evt) {
    evt.preventDefault()

    const hideIds = getElementsIds(evt, 'hide')
    const showIds = getElementsIds(evt, 'show')

    hideIds.forEach((id) => performActionOnId(id, 'fadeOut'))
    showIds.forEach((id) => performActionOnId(id, 'fadeIn'))

    return false
  })

  // Do nothing for empty links
  $(document).on('click', "a[href=''], [data-element-link='']", function (evt) {
    evt.preventDefault()
    return false
  })

  $(document).on('click', '[data-element-link]', function (evt) {
    evt.preventDefault()
    const linkableElement = evt.currentTarget
    const link = linkableElement.getAttribute('data-element-link')
    if (link && !internalLinks.includes(link)) {
      const target = linkableElement.getAttribute('target') ?? '_self'
      if (target === '_self') {
        window.location.href = link
      } else {
        window.open(link, '_blank')
      }
    }
  })

  document.querySelectorAll('[data-element-link]').forEach((linkableElement) => {
    if (linkableElement.getAttribute('data-element-link')) linkableElement.setAttribute('tabindex', '0')
  })
}

window.addEventListener('load', setupActionLinkListeners)
