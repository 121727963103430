
import "./modal-v1"
import "./store-search-products"
import { mountComponent } from '@yggdrasil/Store/Search/storeSearch'
import { CF2Component, registerComponent } from 'javascript/lander/runtime'

export default class StoreSearch extends CF2Component {

constructor(el, runtimeSel) {
super(el, runtimeSel)
}


  
    mount() {
     mountComponent(this)
    }



}

registerComponent('StoreSearch', StoreSearch)
window["StoreSearch"] = StoreSearch

